<template>
  <div class="schedule">
        <div class="schedule-tabs">   
            <div class="schedule-li-tab" v-for="(item,index) in tabsParam" @click="toggleTabs(index)"
                :class="{active:index == nowIndex}" :key="index">{{item.text}}</div>
        </div>
        <div class="schedule-divTab" v-show="nowIndex === index">
            <div class="schedule-list" v-for="(item,index) in activityList" :key="index" >
                <div v-if="item.status == 0 || item.status == 10">
                    <div class="schedule-list-title" @click="goPerDetail(index)">
                        <p>订单编号：{{item.code}}</p>
                        <span v-if="item.status == 0">待完善</span>
                        <span v-if="item.status == 1">待审核</span>
                        <span v-if="item.status == 2">待签约</span>
                        <span v-if="item.status == 3">可挑战</span>
                        <span v-if="item.status == 4">延期</span>
                        <span v-if="item.status == 5">内部审核中</span>
                        <span v-if="item.status == 6">已签约</span>
                        <span v-if="item.status == 7">已取消</span>
                        <span v-if="item.status == 8">已完成</span>
                        <span v-if="item.status == 9">线下审核中</span>
                        <span v-if="item.status == 10">驳回</span>
                        <img src="../../assets/img/yanqi.jpg" alt="" class="delay" v-if="item.status == 4">
                    </div>
                    <div class="schedule-center">
                        <div class="schedule-center-img" @click="goPerDetail(index)">
                            <img :src="item.thumbnail" alt="">
                            <div>
                                <h2>{{item.name}}</h2>
                                <p>{{item.ename}}</p>
                                <p>{{item.startTime}} 至 {{item.endTime}}</p>
                                <span class="grand-hall">#{{item.venueType}}#</span>
                            </div>
                        </div>
                        <div class="schedule-center-status">
                            <div v-if="item.status == 0">
                                <button type="button" class="status-blue" @click="goPerDetail(index)">完善信息</button>
                                <button type="button" class="status-grey" style="margin-top:10px;" @click="onDelete(index)">取消</button>
                            </div>
                            <div v-if="item.status == 10">
                                <button type="button" class="status-blue" @click="goEdit(index)">修改信息</button>
                                <button type="button" class="status-grey" style="margin-top:10px;" @click="onDelete(index)">取消</button>
                            </div>
                            <button type="button" class="status-grey" v-if="item.status == 1" @click="onDeletea(index)">取消</button>
                            <button type="button" class="status-grey" v-if="item.status == 2" @click="goPerDetail(index)">上传附件</button>
                            <button type="button" class="status-grey" v-if="item.status > 2 && item.status < 10" @click="goPerDetail(index)">查看</button>
                        </div>
                    </div>
                </div>

                <div v-else>
                    <div class="schedule-list-title" @click="goSonalDetail(index)">
                        <p>订单编号：{{item.code}}</p>
                        <span v-if="item.status == 0">待完善</span>
                        <span v-if="item.status == 1">待审核</span>
                        <span v-if="item.status == 2">待签约</span>
                        <span v-if="item.status == 3">可挑战</span>
                        <span v-if="item.status == 4">延期</span>
                        <span v-if="item.status == 5">内部审核中</span>
                        <span v-if="item.status == 6">已签约</span>
                        <span v-if="item.status == 7">已取消</span>
                        <span v-if="item.status == 8">已完成</span>
                        <span v-if="item.status == 9">线下审核中</span>
                        <span v-if="item.status == 10">驳回</span>
                    </div>
                    <div class="schedule-center">
                        <div class="schedule-center-img" @click="goSonalDetail(index)">
                            <img :src="item.thumbnail" alt="">
                            <div>
                                <h2>{{item.name}}</h2>
                                <p>{{item.ename}}</p>
                                <p>{{item.startTime}} 至 {{item.endTime}}</p>
                                <span class="grand-hall">#{{item.venueType}}#</span>
                            </div>
                        </div>
                        <div class="schedule-center-status">
                            <div v-if="item.status == 0">
                                <button type="button" class="status-blue" @click="goSonalDetail(index)">完善信息</button>
                                <button type="button" class="status-grey" style="margin-top:10px;" @click="onDelete(index)">取消</button>
                            </div>
                            <button type="button" class="status-grey" v-if="item.status == 1" @click="onDeletea(index)">取消</button>
                            <button type="button" class="status-grey" v-if="item.status == 2" @click="goSonalDetail(index)">上传附件</button>
                            <div v-if="item.status == 8">
                                <button type="button" class="status-orange" v-if="item.status == 8 && item.suggested == false" @click="onAssess(index)">去评价</button>
                                <button type="button" class="status-orange" v-if="item.status == 8 && item.suggested == true" :disabled="true">已评价</button>
                            </div>
                            <button type="button" class="status-grey" v-if="item.status > 2" @click="goSonalDetail(index)">查看</button>
                        </div>
                    </div>
                </div>

                
            </div>

            <div class="none_bg" v-if="activityList.length == 0">
                <img src="../../assets/img/none_bg.png" alt="">
            </div>
        </div>
  </div>
</template>

<script>
import { listActivityByCompany,canceled } from "../../api/personal"
export default {
    data(){
        return{
            tabsParam: [{
                status: -1,
                text: '全部'
            },{
                status: 0,
                text: '待完善'
            },{
                status: 1,
                text: '待审核'
            },{
                status: 2,
                text: '待签约'
            },{
                status: 6,
                text: '已签约'
            },{
                status: 8,
                text: '归档'
            }],
            nowIndex: 0,
            index:0,
            status:-1,
            activityList:[],
            id:"",
            code:"",
            activeStatus:""
        }
    },
    created(){
        this.listActivityByCompany()
    },
    methods:{
        toggleTabs(index){
            console.log(index)
            this.nowIndex = index
            this.index = index
            this.status = this.tabsParam[index].status
            this.listActivityByCompany()
        },

        goDetail(){
            this.$router.push('/perDetail')
        },

        //查看
        goPerDetail(index){
            console.log(index)
            this.id = this.activityList[index].id
            console.log(this.id)
            this.$router.push({
                path: "/perDetail", 
                query: {
                    id:this.id
                }
            });
        },

        //修改信息
        goEdit(index){
            this.id = this.activityList[index].id
            this.$router.push({
                path: "/perDetail", 
                query: {
                    id:this.id
                }
            });
        },
        
        //评价
        onAssess(index){
            this.activeStatus = this.activityList[index].status
            this.id = this.activityList[index].id
            this.$router.push({
                path: "/sonalDetail", 
                query: {
                    id:this.id,
                    activeStatus:this.activeStatus
                }
            });
        },

        //完善信息
        goSonalDetail(index){
            // console.log(index)
            this.id = this.activityList[index].id
            console.log( this.id)
            this.$router.push({
                path: "sonalDetail", 
                query: {
                    id:this.id
                }
            });
        },

        //列表
        listActivityByCompany(){
            listActivityByCompany({
                status:this.status
            }).then((res) => {
                if(res.data.code == 20000){
                    this.activityList = res.data.data.activityList
                }else if(res.data.code == 20005){
                    this.$message.error(res.data.message)
                    this.$router.push("/login")
                }
            })
        },

        //取消
        onDelete(index){
            this.code = this.activityList[index].code
            this.$confirm("确认取消？")
            .then((_) => {
                console.log('取消了----');
                canceled({
                    code:this.code
                }).then((res) => {
                    if(res.data.code == 20000){
                        this.listActivityByCompany()
                    }
                })
            })
            .catch((_) => {
                console.log('取消不了----');
            });
        },

        //撤回
        onDeletea(index){
            this.code = this.activityList[index].code
            this.$confirm("确认撤回？")
            .then((_) => {
                console.log('撤回了----');
                canceled({
                    code:this.code
                }).then((res) => {
                    // console.log(res)
                    if(res.data.code == 20000){
                        this.listActivityByCompany()
                    }
                })
            })
            .catch((_) => {
                console.log('撤回不了----');
            });
        }
    }
}
</script>

<style scoped lang="scss">
.schedule{
    width:100%;
    .schedule-tabs{
        width:100%;
        background-color:#FFFFFF;
        display:flex;
        align-items:center;
        padding:0 30px;
        .schedule-li-tab{
            padding:16px 0;
            color:#333333;
            font-size:16px;
            margin-right:40px;
            cursor: pointer;
        }
        .active{
            color:#123E90;
            border-bottom:2px solid #123E90;
        }
    }
    .schedule-divTab{
        width:100%;
        margin-top:10px;
        height:78vh;
        overflow:auto;
        margin-bottom:30px;
        .schedule-list{
            margin-bottom:10px;
            background-color:#FFFFFF;
            .schedule-list-title{
                width:100%;
                height:50px;
                padding:0 30px;
                border-bottom:1px solid #EEEEEE;
                display:flex;
                align-items:center;
                justify-content: space-between;
                cursor: pointer;
                position: relative;
                p{
                    color:#666666;
                    font-size:14px;
                }
                span{
                    color:#FF7100;
                    font-size:14px;
                }
                img{
                    width:42px;
                    height:42px;
                    position:absolute;
                    right:0;
                    top:0;
                }
            }
            .schedule-center{
                width:100%;
                height:140px;
                padding:20px 30px;
                display:flex;
                align-items:center;
                justify-content: space-between;
                .schedule-center-img{
                    display:flex;
                    cursor: pointer;
                    img{
                        width:155px;
                        height:100px;
                        border-radius:4px;
                    }
                    div{
                        height:100px;
                        margin-left:10px;
                        display:flex;
                        flex-direction: column;
                        justify-content: space-between;
                        h2{
                            color:#333333;
                            font-size:18px;
                        }
                        p{
                            color:#989898;
                            font-size:12px;
                        }
                    }
                }
                .schedule-center-status{
                    height:100px;
                    display:flex;
                    flex-direction: column;
                    align-items:center;
                    justify-content: center;
                    button{
                        width:75px;
                        height:30px;
                        color:#666666;
                        font-size:13px;
                        display:flex;
                        align-items:center;
                        justify-content: center;
                        border:none;
                        border-radius:15px;
                        background-color:#ffffff;
                    }
                    .status-blue{
                        border:1px solid #003C96;
                        color:#003C96;
                    }
                    .status-grey{
                        border:1px solid #CFCFCF; 
                    }
                    .status-orange{
                        border:1px solid #FF7100;
                        color:#FF7100;
                        margin-bottom:10px;
                    }
                }
            }
        }
    }
    .none_bg{
        width:100%;
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        img{
        width:200px;
        height:200px;
        }
    }
}
</style>